import { Link } from "gatsby"
import React from "react"
import css from "./LinkButton.module.scss"
export default function LinkButton(prop) {
  const myClass = prop.color === "invert" ? css.invert : css.normal
  const target = prop.target ? prop.target : "_self"

  let elButton = (
    <Link to={prop.to} className={myClass} target={target}>
      {prop.children}
    </Link>
  )
  if (prop.to && prop.to.indexOf(".pdf") !== -1) {
    elButton = (
      <a href={prop.to} className={myClass} target={target}>
        {prop.children}
      </a>
    )
  } else if (prop.disabled) {
    elButton = <span className={myClass}>{prop.children}</span>
  }

  return <div className={css.base}>{elButton}</div>
}
