import { Link } from "gatsby"
import React, { Component } from "react"
import css from "./stylesheets/GlobalNavi.module.scss"
import { Box } from "@material-ui/core"
import LinkButton from "./LinkButton"
import BpHr from "./BpHr"

export default class GlobalNavi extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMenuOpen: false,
      invert: props.invert ? props.invert : false,
    }
    this._toggleMenu = this._toggleMenu.bind(this)
  }

  _toggleMenu() {
    const isMenuOpen = !this.state.isMenuOpen
    this.setState(state => ({
      isMenuOpen: isMenuOpen,
    }))
  }

  render() {
    const iconClass = this.state.isMenuOpen ? `iconMenuClose` : `iconMenuOpen`

    const invertStyle =
      this.state.invert && !this.state.isMenuOpen ? { filter: `invert(1)` } : {}

    return (
      <div>
        <button className={`iconMenu ` + css.button} onClick={this._toggleMenu}>
          <span className={`iconMenu__hiddenText`}>
            メインメニューを開閉する
          </span>
          <i className={iconClass} style={invertStyle}>
            <span />
            <span />
            <span />
          </i>
        </button>

        {this.state.isMenuOpen && (
          <div className={css.menu} aria-label="メインメニュー">
            <div className="l-wrapper">
              <Box
                pt={{ xs: "20px", sm: `60px` }}
                pb={{ xs: "20px", sm: `60px` }}
                className={css.menuContent}
              >
                <div className={css.menuContent__head}>
                  <h2 className={css.menuContent__title + ` fontLvH3`}>
                    <Link to={`/`}>
                      ヨコハマ・パラトリエンナーレ2020 BOOK <br />
                      PROJECT「そのうち届くラブレター」
                    </Link>
                  </h2>
                </div>

                <nav className={css.menuContent__nav}>
                  <Box>
                    <LinkButton color={`invert`} to={"/works/"}>
                      ブックで紹介したすべての作品
                    </LinkButton>
                    <LinkButton color={`invert`} to={"/talk/"}>
                      おしゃべり対話鑑賞
                    </LinkButton>
                    <LinkButton color={`invert`} to={"/profile/"}>
                      作家とナビゲーターの紹介
                    </LinkButton>
                  </Box>
                  <BpHr type={`dot`} color={`invert`} />
                  <Box>
                    <LinkButton color={`invert`} to={"/about/"}>
                      このプロジェクトについて
                    </LinkButton>
                    <LinkButton color={`invert`} to={"/bookproject/"}>
                      ブックを手に入れる
                    </LinkButton>
                  </Box>
                  <Box className={css.metaLink}>
                    <p className={`fontLvBody ` + css.languageLink}>
                      {this.props.lang === `en` ? (
                        <Link to={`/`}>日本語</Link>
                      ) : (
                        <Link to={`/en`}>English</Link>
                      )}
                    </p>
                    <p className={`fontLvBodyS ` }>
                      <a href={`https://www.paratriennale.net/privacy-policy/`} target={`_blank`}>個人情報保護方針</a>
                    </p>
                  </Box>
                </nav>
              </Box>
            </div>
          </div>
        )}
      </div>
    )
  }
}
