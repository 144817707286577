import React from "react"
import css from "./BpHr.module.scss"
export default function BpHr(prop) {
  const myClass = prop.type === "dot" ? css.dot : css.line
  const myColor = prop.color === "invert" ? css.invert : css.normal
  return (
    <div>
      <hr className={myClass + ` ` + myColor} />
    </div>
  )
}
